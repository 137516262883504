import ImageAudioSequence from "../components/image_audio_sequence"
import { graphql } from "gatsby"

export default ImageAudioSequence

export const pageQuery = graphql`
  query($slug: String!) {
    allMarkdownRemark {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
        edges {
          node {
            fields {
              slug
            }
          }
        }
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      html
      frontmatter {
        title
        tags
      }
      fields {
        slug
        table
      }
    }
  }
`
