import React from "react"
import Layout from "./layout"
import { graphql } from "gatsby"
import InnerHTML from 'dangerously-set-html-content'


export default function ImageAudio({data,location}) {
  const post = data.markdownRemark
  return (
    <Layout data={data}>
    </Layout>
  )
}
